<template>
  <div>
    <vl-region mod-no-space-top>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <vl-title class="vl-u-spacer--none">{{ $t('payments.overview.label') }}</vl-title>
            <div class="mx-input-wrapper">
              <input
                class="mx-input"
                type="text"
                v-model="searchQuery"
                :placeholder="$t('payments.overview.searchPlayholder')"
              />
              <DatePicker v-model="date" range></DatePicker>
            </div>
            <JsonCSV
              delimiter=";"
              class="vl-button button-margin"
              :data="resultQuery"
              :csvFields="csvHeaderFields"
              :advanced-options="{ columns: csvHeaderFields }"
              >{{ $t('payments.overview.exportCSV') }}</JsonCSV
            >
          </vl-column>
          <vl-column v-if="error">
            <vl-alert mod-error icon="info-circle">
              {{ error }}
            </vl-alert>
          </vl-column>
          <vl-column>
            <vl-data-table>
              <thead>
                <tr>
                  <th>{{ $t('payments.overview.tableOverviewHeaders.amount') }}</th>
                  <th>{{ $t('payments.overview.tableOverviewHeaders.communication') }}</th>
                  <th>{{ $t('payments.overview.tableOverviewHeaders.documentId') }}</th>
                  <th>{{ $t('payments.overview.tableOverviewHeaders.PaymentDate') }}</th>
                  <th>{{ $t('payments.overview.tableOverviewHeaders.PaymentMethod') }}</th>
                  <th>{{ $t('payments.overview.tableOverviewHeaders.PaymentStatus') }}</th>
                  <th>{{ $t('payments.overview.tableOverviewHeaders.transactionId') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(payment, index) in displayedRecords" :key="index">
                  <td>{{ payment.amount }}</td>
                  <td>{{ payment.communicationPart1 }}</td>
                  <td>{{ payment.documentId }}</td>
                  <td>{{ payment.paymentDate }}</td>
                  <td>{{ payment.paymentMethod }}</td>
                  <td>{{ payment.status }}</td>
                  <td>{{ payment.transactionId }}</td>
                </tr>
              </tbody>
            </vl-data-table>
          </vl-column>
          <pagination v-model="page" :per-page="perPage" :records="listSize" />
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>

<script>
import Pagination from 'vue-pagination-2';
import JsonCSV from 'vue-json-csv';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  components: {
    Pagination,
    JsonCSV,
    DatePicker,
  },
  data() {
    return {
      token: '',
      paymentLink: '',
      error: '',
      payments: [],
      searchQuery: '',
      perPage: 15,
      page: 1,
      date: null,
      organization: {},
    };
  },
  async created() {
    try {
      const user = this.$store.getters['app/user'];
      this.$store.dispatch('organization/get', user.organization.id).then(async response => {
        if (Object.keys(response).length === 0) {
          this.organization = await this.$store.dispatch('organization/add', user.organization);
        } else {
          this.organization = response;
        }
        this.$store.dispatch('tenant/get').then(async response => {
          if (Object.keys(response).length === 0) {
            await this.$store.dispatch('tenant/add', { organizationId: this.organization.id });
          }
        });
        this.payments = await this.$store.dispatch('payment/payments');
      });
    } catch (err) {
      this.error = err.message;
    }
  },
  watch: {
    // every time the filtered list size is altered: change the pagination back to page 1
    listSize: function() {
      this.page = 1;
    },
  },
  computed: {
    resultQuery() {
      let result = this.payments;
      if (this.searchQuery) {
        result = this.payments.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(v => {
              let check = false;
              Object.keys(item).forEach(key => {
                if (
                  String(item[key])
                    .toLowerCase()
                    .includes(String(v))
                ) {
                  check = true;
                }
              });
              return check;
            });
        });
      }
      if (this.date && this.date[0] != null && this.date[1] != null) {
        const from = new Date(this.date[0]);
        const to = new Date(this.date[1]);
        result = result.filter(item => {
          if (item.paymentDate === undefined) {
            return true;
          }
          const check = new Date(item.paymentDate);
          return check > from && check < to;
        });
      }
      return result;
    },
    listSize() {
      return this.resultQuery.length;
    },
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.resultQuery.slice(startIndex, endIndex);
    },
    csvHeaderFields() {
      return [
        'documentId',
        'amount',
        'documentDate',
        'documentTitle',
        'expiryDate',
        'status',
        'paymentDate',
        'productId',
        'communication',
        'communicationPart1',
        'transactionId',
      ];
    },
  },
};
</script>
<style>
@import '~bootstrap/dist/css/bootstrap.css';
input,
ul,
p {
  font-size: 1.5rem;
}
.button-margin {
  margin-top: 5px;
}
.mx-datepicker-range {
  width: 200px;
}
.mx-input {
  width: 200px;
  margin-right: 5px;
}
</style>
